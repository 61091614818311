@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,800,900|Open+Sans:700,800&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Round');

:root {
  --primary-dark-color: #15293E;
  --primary-light-color: #e6e6e6;
  --hl-color: yellow;
}

* {
  font-family: 'Poppins', sans-serif;
  color: var(--primary-light-color);
  outline: none;
}

a:hover {
  color: var(--hl-color);
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  background-color: var(--primary-dark-color);
}

body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid 1px transparent;
  box-sizing: border-box;
}

main {
  margin: 0 auto;
  padding: 0 8vw;
}

#heyo {
  position: relative;
  margin-top: 6rem;
}

#heyo img {
  width: 158px;
  height: 158px;
  margin-bottom: 2vh;
}

h1 {
  font-weight: 800;
  font-size: 2.4rem;
}

#heyo h1 {
  margin: 0;
  color: var(--primary-dark-color);
}

#heyo .pitch {
  margin-top: 0;
  font-size: 1.6rem;
  color: var(--primary-dark-color);
}

h2 {
  font-size: 2rem;
}

#home-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-image: url(images/bg.png);
  background-position: top;
  background-repeat: no-repeat;
}

#home {
  text-align: center;
}

#help ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#help li {
  margin-top: 4vh;
}

#faq .questions li {
  margin-top: 2vh;
}

#faq h3 {
  margin-top: 8vh;
}

#mc_embed_signup {
  margin-top: 6rem;
}

#mc_embed_signup label {
  display: block;
  font-size: 1.82rem;
  margin-bottom: 4vh;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}

#mc_embed_signup label div {
  margin-top: 1rem;
}

#mc_embed_signup input[type="email"] {
  color: var(--hl-color);
  font-size: 1.2rem;
  padding-left: .4rem;
  width: 90%;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px #e6e6e6;
}

#mc_embed_signup input[type="email"]:hover
, #mc_embed_signup input[type="email"]:focus {
  border-bottom-color: var(--hl-color);
}

#mc_embed_signup input[type="email"]::placeholder {
  color: #93A6B1;
}

#mc_embed_signup input[type="submit"] {
  color: #15293e;
  font-size: 1.2rem;
  padding: .32rem 1.2rem;
  margin-top: 4vh;
  cursor: pointer;
  background-color: var(--hl-color);
  border: none;
  border-radius: 24px;
}

.three-acts {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8rem;
  list-style-type: none;
  padding: 0;
}

.three-acts li {
  width: 100px;
  text-align: center;
  font-size: .82rem;
}

.three-acts li span {
  display: block;
  font-size: 3rem;
  margin-bottom: .68rem;
}

.testimonials {
  margin-top: 6rem;
}

.testimonials h3 {
  font-weight: normal;
}

.awards {
  margin-top: 4rem;
}

.awards img {
  width: 200px;
  height: 200px;
}

.v2-android {
  margin-top: 6rem;
}

.v2-android a {
  display: block;
  margin-top: 1rem;
}

.v2-android a img {
  max-width: 200px;
}

footer {
  margin: 8rem 0 4vh 0;
  font-size: .86rem;
}

nav {
  display: inline;
}

nav a {
  margin-right: 1rem;
}

footer .copyright {
  margin-top: 1rem;
}


@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2.8rem;
  }

  #mc_embed_signup label div {
    margin-top: 0;
  }

  #mc_embed_signup input[type="email"] {
    width: 16rem;
  }

  #mc_embed_signup input[type="submit"] {
    margin-top: 0;
    margin-left: 1rem;
  }
  
  .three-acts li {
    width: 200px;
    text-align: center;
    font-size: 1rem;
  }
  
  .three-acts li span {
    display: block;
    font-size: 4rem;
    margin-bottom: .68rem;
  }
}

@media only screen and (min-width: 1024px) {
  main {
    max-width: 800px;
  }
}

@media only screen and (min-width: 2560px) {

}


.carousel .slide {
  padding-bottom: 3rem;
  background: transparent !important;
}

.carousel .slide br {
  margin-top: 10rem;
}

.carousel .slide a {
  border: solid 1px var(--primary-light-color);
  padding: .2rem 1rem;
  border-radius: 16px;
  text-decoration: none;
}

.carousel .slide a:hover {
  border-color: var(--hl-color);
}